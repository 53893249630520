import { BodyParagraph, Post } from '@ui-kit/main-api-types';
import { TopPost } from '@ui-kit/main-frontend-ui-kit/dist/src/components/TopPost';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import { useRouter } from 'next/router';

import { TRIBUNA_COM_URL } from '@utils/constants';

interface ITopPostProps {
	post: Post;
}

const TopPostHOC = ({ post }: ITopPostProps) => {
	const { locale } = useRouter();

	const postTitle = post?.title?.defaultValue || post?.title?.[locale];
	const postImageUrl = post?.topPhoto?.url || post?.mainPhoto?.url;
	const localizedStructuredDescription = post?.structuredDescription?.[locale];
	const postDescription =
		(localizedStructuredDescription?.[0]?.value as BodyParagraph)?.elements?.[0]
			?.value?.text || '';

	const commentsCount = post?.commentsCount;

	const blog = post?.blog;
	const blogHru = blog?.hru;
	const blogId = blog?.id;
	const blogLinkID = blogHru || blogId;
	const label = post?.label;

	const time = (dayjs.tz(post?.publicationTime * 1000) as any).fromNow();
	const postHruOrId = post?.hru || post?.id;
	const postLink =
		post?.link || `${TRIBUNA_COM_URL}${locale}/blogs/${postHruOrId}/`;

	return (
		<TopPost
			label={label}
			title={postTitle}
			imageUrl={postImageUrl}
			commentsCount={commentsCount}
			postLink={postLink}
			formattedDate={time}
			postId={post?.id}
			postDescription={postDescription}
		/>
	);
};

export default TopPostHOC;
